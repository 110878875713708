<h1 mat-dialog-title>{{ data.title | translate }}</h1>
<div mat-dialog-content class="mat-typography">
    <pre class="bg-neutral-100 dark:bg-neutral-800 rounded-lg p-2 overflow-auto"
        >{{ data.content ? data.content : (data.jsonContent | json) }}
</pre
    >
    <sz-dialog-button-bar
        [primaryText]="'common.copy' | translate"
        (primaryClick)="copyData()"
        (secondaryClick)="dialogRef.close()"
    />
</div>
